<template>
  <div>
    <a-card :title="$t('库存明细')">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.status" :placeholder="$t('状态')" allowClear style="width: 100%" @change="search">
            <a-select-option key="qualified" value="qualified">{{ $t("良品") }}</a-select-option>
            <a-select-option key="unqualified" value="unqualified">{{ $t("不良品") }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.batch_number" :placeholder="$t('批次编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.location_number" :placeholder="$t('库位编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('产品编号, 名称')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.pallet_number" :placeholder="$t('容器编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" style="width: 400px; margin-bottom: 12px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">{{ $t("批量入库模板下载") }}</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">{{ $t("批量入库导入") }}</a-button>
            </a-upload>

            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
          </a-button-group>
        </a-col>
      </a-row>

      <a-row>
        <a-table
          size="small"
          :columns="curColumns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ x: 2220 }"
          @change="tableChange"
        >
          <template slot="assigned_quantity" slot-scope="value, item">
            <a-button type="link" size="small" @click="openDetailModal(item)">{{ value }}</a-button>
          </template>
          <div slot="action" slot-scope="value, item">
            <a-button type="primary" size="small" @click="openTransitionModal(item)">
              {{ item.status == "unqualified" ? $t("转良品") : $t("转不良品") }}
            </a-button>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>
    <detail-modal v-model="detailVisivle" :items="detailItems" />
    <form-modal v-model="visible" :form="targetItem" @update="update" />

    <a-modal
      v-model="transitionVisible"
      :title="targetItem.status == 'unqualified' ? $t('转良品') : $t('转不良品')"
      :maskClosable="false"
      :closable="false"
      :confirmLoading="transitionLoading"
      @ok="transition"
    >
      <div>
        <a-form-model-item :label="$t('数量')" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
          <a-input v-model="transitionQuantity" />
        </a-form-model-item>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { warehouseOption } from "@/api/option";
import { subBatchList, subBatchExport, emptyInventory, subBatchTransition } from "@/api/material";
import { exportExcel } from "@/utils/excel";
import { subBatchTemplate, batchStockInImport } from "@/api/import";
import columns from "./columns.js";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    DetailModal: () => import("./DetailModal"),
    FormModal: () => import("./FormModal"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, ordering: undefined, has_stock: true },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],

      visible: false,
      targetItem: {},
      form: {},
      importLoading: false,

      warehouseItems: [],
      detailItems: [],
      detailVisivle: false,

      transitionQuantity: 1,
      transitionVisible: false,
      transitionLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    enableQualityInspection() {
      return this.$store.state.user.enableQualityInspection;
    },
    curColumns() {
      const items = [];
      for (const item of columns) {
        if (!this.enableQualityInspection && item.key === "quality_inspection_status") {
          continue;
        }

        items.push(item);
      }
      return items;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
      });
    },
    list() {
      this.loading = true;
      subBatchList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    exportExcel() {
      subBatchExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("库存明细"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      subBatchTemplate().then((resp) => {
        exportExcel(resp, this.$t("批量入库导入模版"));
      });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      batchStockInImport(data)
        .then(() => {
          this.$message.success(this.$t("批量入库导入成功"));
          this.list();
        })
        .catch((err) => {
          alert(this.$t("导入错误:") + "\n" + err.response.data.join("\n"));
        })
        .finally(() => {
          this.importLoading = false;
        });
    },
    update(item) {
      this.list();
    },
    emptyInventory() {
      emptyInventory().then(() => {
        this.$message.success(this.$t("库存已清空"));
        this.list();
      });
    },
    openDetailModal(item) {
      this.detailItems = item.recommend_location_items;
      this.detailVisivle = true;
    },
    openTransitionModal(item) {
      this.targetItem = item;
      this.transitionQuantity = 1;
      this.transitionVisible = true;
    },
    transition() {
      this.transitionLoading = true;
      subBatchTransition({ id: this.targetItem.id, quantity: this.transitionQuantity })
        .then((data) => {
          this.update(data);
          this.transitionVisible = false;
        })
        .finally(() => {
          this.transitionLoading = false;
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
